import Content from '@components/Content';
import Icon from '@components/IconOutline';
import {
  Body1Text,
  Headline4Text,
  Headline6Text,
  LinkText,
} from '@components/Text';
import { classNames } from 'lib';
import { useState } from 'react';

interface Props {
  faqs: {
    question: string;
    answer: string;
  }[];
}

export default function FrequentlyAskedQuestions({ faqs }: Props) {
  const [expanded, setExpanded] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleExpand = (index: number) => () => {
    setExpanded(expanded.map((c, i) => (i === index ? true : c)));
  };

  const handleCollapse = (index: number) => () => {
    setExpanded(expanded.map((c, i) => (i === index ? false : c)));
  };

  return (
    <div className="bg-white">
      <Content className="py-12">
        <div className="lg:grid lg:grid-cols-3 lg:gap-10">
          <div className="space-y-4">
            <Headline4Text element="h2" className="-mb-1">
              Frequently asked questions
            </Headline4Text>
            <Body1Text>
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <LinkText href="mailto:support@offertrackr.com">
                customer support
              </LinkText>{' '}
              team.
            </Body1Text>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <div className="space-y-6">
              {faqs.map((faq, index) => (
                <div
                  className={classNames(
                    'border-b pb-6',
                    index === faqs.length - 1
                      ? 'border-transparent'
                      : 'border-greyMed',
                  )}
                  key={faq.question}
                >
                  {!expanded[index] && (
                    <div
                      className="flex cursor-pointer select-none flex-row justify-between"
                      onClick={handleExpand(index)}
                    >
                      <Headline6Text element="h3" className="">
                        {faq.question}
                      </Headline6Text>
                      <Icon
                        id="expand"
                        className="mt-1 ml-3 h-6 w-6 shrink-0 self-start hover:text-blueMed"
                      />
                    </div>
                  )}
                  {!!expanded[index] && (
                    <>
                      <div
                        className="flex cursor-pointer select-none flex-row justify-between  "
                        onClick={handleCollapse(index)}
                      >
                        <Headline6Text element="h3" className="mb-1">
                          {faq.question}
                        </Headline6Text>
                        <Icon
                          id="collapse"
                          className="mt-1 h-6 w-6 cursor-pointer hover:text-blueMed"
                        />
                      </div>
                      <Body1Text className="pr-10 text-greyDk">
                        {faq.answer}
                      </Body1Text>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
}

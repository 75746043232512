import Content from '@components/Content';
import { Body1Text, Headline4Text, Headline5Text } from '@components/Text';
import Image from 'next/image';

const features = [
  {
    name: 'Create a Trackr',
    description:
      'It takes just a few clicks to setup a new OfferTrackr (we call it a Trackr for short). Tell us the price of the item and when you are available for pickups.',
    image: 'how_create_trackr.png',
  },
  {
    name: 'List your item',
    description:
      'Copy and paste our simple instructions into the description of your listing. This lets buyers know to text your Trackr. Your Trackr will manage your sale.',
    image: 'how_list_item.png',
  },
  {
    name: 'Meet buyers for pickup',
    description:
      'Once an offer is accepted, meet the buyers at the scheduled time and collect the already negotiated payment.',
    image: 'how_meet_buyers.png',
  },
];

export default function HowItWorks() {
  return (
    <div id="how-it-works" className="bg-warmWhite">
      <Content className="pt-14">
        <div className="mb-9 lg:text-center">
          <Headline4Text element="h2">How It Works</Headline4Text>
        </div>

        <div className="space-y-8 md:space-y-10 lg:grid lg:grid-cols-3 lg:gap-9 lg:space-y-0">
          {features.map((feature, index) => (
            <div key={feature.name} className="w-full">
              <div className="relative">
                <Image
                  src={`https://cdn.offertrackr.com/${feature.image}`}
                  width={378}
                  height={324}
                  alt={feature.name}
                  className="rounded-lg"
                />
              </div>
              <Headline5Text element="h3" className="mt-4">
                {' '}
                <span className="mr-1 font-Lexend text-blueMed">
                  {index + 1}.
                </span>{' '}
                {feature.name}
              </Headline5Text>
              <Body1Text className="mt-3 align-baseline">
                {feature.description}
              </Body1Text>
            </div>
          ))}
        </div>
      </Content>
    </div>
  );
}

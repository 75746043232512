import IconOutline from './IconOutline';
import { Body1Text } from './Text';

interface Props {
  message: string;
  status?: string;
  closeToast?: () => {};
}

function ToastNotification({ message, status = 'success', closeToast }: Props) {
  let icon: OutlineIcon = 'check-circle';
  let color = 'bg-tealDk';

  if (status === 'error') {
    icon = 'exclamation-circle';
    color = 'bg-redDk';
  }

  return (
    <div
      className={`${color} pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg p-2`}
      onClick={closeToast}
    >
      <div className="">
        <div className="flex items-start">
          <div className="my-auto ml-1 flex-shrink-0">
            <IconOutline
              id={icon}
              className={`h-8 w-8 text-white`}
              aria-hidden="true"
            />
          </div>
          <div className="my-auto ml-3 w-0 flex-1 pt-0.5 pr-1">
            <Body1Text className="mt-0 text-white">{message}</Body1Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default async function showToast(
  msg: string,
  status: string = 'success',
) {
  const toast = (await import('react-toastify')).toast;
  toast(<ToastNotification message={msg} status={status} />);
}

import { classNames } from '../../lib/src/format';
import { Body2Text } from './Text';

interface Props {
  show: boolean;
  children: any;
  className?: string;
  [x: string]: any;
}

export default function HelpMessage({
  show,
  className,
  children,
  ...props
}: Props) {
  return show && children ? (
    <Body2Text className={classNames(' text-greyDk', className)}>
      {children}
    </Body2Text>
  ) : (
    <></>
  );
}

import { Button } from '@components/Button';
import Content from '@components/Content';
import { Body1Text, Headline4Text } from '@components/Text';
import Link from 'next/link';

export default function CallToAction() {
  return (
    <div className="bg-blueMed">
      <Content className="py-20 text-center">
        <Headline4Text element="h2" className="pb-2 text-white">
          Make more money with less hassle.
        </Headline4Text>
        <Headline4Text element="h3" className="text-white">
          Start with two free Trackrs.
        </Headline4Text>
        <Body1Text className=" mt-3 mb-6 text-lg text-orangeLt md:text-xl">
          No credit card required.
        </Body1Text>
        <div className="mx-auto w-full  md:w-fit">
          <Link href="/signin" className="w-full md:w-fit">
            <Button id="start_trial_cta" theme="yellow">
              Get Started
            </Button>
          </Link>
        </div>
      </Content>
    </div>
  );
}

import Head from 'next/head';
import { useRouter } from 'next/router';

interface Props {
  title: string;
  description?: string;
  className?: string;
  children: any;
}

export default function Layout({
  title = 'OfferTrackr',
  description,
  className,
  children,
}: Props) {
  const router = useRouter();

  return (
    <div className={` ${className} overflow-hidden `}>
      <Head>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div className="mb-auto">{children}</div>
    </div>
  );
}

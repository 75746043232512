import { Button } from '@components/Button';
import Content from '@components/Content';
import ErrorMessage from '@components/ErrorMessage';
import { Body2Text, Headline6Text } from '@components/Text';
import TextInput from '@components/TextInput';
import showToast from '@components/ToastNotification';
import { BLOG_URL, DOCUMENTATION_URL, HOMEPAGE_URL } from '@lib';
import Link from 'next/link';
import { useState } from 'react';

const navigation = {
  solutions: [
    { name: 'Estate Sales', href: `${HOMEPAGE_URL}/estate-sales` },
    { name: 'Garage Sales', href: `${HOMEPAGE_URL}/garage-sales` },
    { name: 'Charity Auctions', href: `${HOMEPAGE_URL}/charity-auctions` },
    { name: 'Social Media', href: `${HOMEPAGE_URL}/social-media` },
    { name: 'Local Marketplaces', href: `${HOMEPAGE_URL}/local-marketplaces` },
  ],
  support: [
    { name: 'Pricing', href: `${HOMEPAGE_URL}/#pricing` },
    { name: 'Help Center', href: `${DOCUMENTATION_URL}/docs` },
    { name: 'Guides', href: `${DOCUMENTATION_URL}/docs/guides` },
  ],
  company: [
    { name: 'About Us', href: `${HOMEPAGE_URL}/about` },
    { name: 'Blog', href: `${BLOG_URL}/blog` },
    { name: 'Careers', href: `${HOMEPAGE_URL}/careers` },
    {
      name: 'Become an Affiliate',
      href: `https://offertrackr.refersion.com/affiliate/registration`,
    },
  ],
  legal: [
    {
      name: 'Terms of Use',
      href: '/terms',
    },
    {
      name: 'Privacy Policy',
      href: '/privacy',
    },
    {
      name: 'Cookies Policy',
      href: '/cookies',
    },
  ],
};

interface Props {
  name: string;
  links: {
    name: string;
    href: string;
  }[];
}

function Section({ name, links }: Props) {
  return (
    <div>
      <Headline6Text element="h2">{name}</Headline6Text>
      <ul role="list" className="mt-6 space-y-4">
        {links.map(item => (
          <li key={item.name}>
            <Body2Text className="hover:text-blueMed">
              <Link href={item.href}>{item.name}</Link>
            </Body2Text>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function Footer() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleChangeEmail = (next: string) => {
    setEmail(next);
    setError('');
  };

  const handleSubscribe = async () => {
    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    const result = await fetch(`${HOMEPAGE_URL}/api/moosend/subscribe`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({
        email,
      }),
    });

    const body = await result.json();
    if (body.error) {
      setError(body.error);
      return;
    }

    setError('');
    setEmail('');
    showToast('Subscribed! Please check your email to confirm.');
  };

  return (
    <footer className="bg-warmWhite" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <Content>
        <div className=" pb-8 pt-16 sm:pt-20 lg:pt-24">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="grid grid-cols-2 gap-8 lg:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <Section name="Use Cases" links={navigation.solutions} />
                <div className="mt-10 md:mt-0">
                  <Section name="Support" links={navigation.support} />
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <Section name="Company" links={navigation.company} />
                <div className="mt-10 md:mt-0">
                  <Section name="Legal" links={navigation.legal} />
                </div>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <Headline6Text element="h2" className="leading-6">
                Subscribe to our newsletter
              </Headline6Text>
              <Body2Text className="mt-2">
                The latest news, updates, and discounts sent to your inbox.
              </Body2Text>
              <div className="mt-6 sm:flex sm:max-w-md sm:flex-row">
                <Body2Text htmlFor="email-address" className="sr-only">
                  Email address
                </Body2Text>
                <TextInput
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  value={email}
                  required
                  placeholder="Enter your email"
                  onChange={handleChangeEmail}
                />
                <div className="mt-4 sm:mt-0 sm:ml-4 sm:justify-center sm:self-center sm:align-middle">
                  <Button id="newsletter-subscribe" onClick={handleSubscribe}>
                    Subscribe
                  </Button>
                </div>
              </div>
              <ErrorMessage show={!!error}>{error}</ErrorMessage>
            </div>
          </div>
          <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
            <Body2Text className="text-greyDk mt-8 leading-5 md:order-1 md:mt-0">
              &copy; {new Date().getFullYear()} OfferTrackr, LLC. All rights
              reserved.
            </Body2Text>
          </div>
        </div>
      </Content>
    </footer>
  );
}

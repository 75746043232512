import { Button } from '@components/Button';
import Content from '@components/Content';
import {
  Body1Text,
  Body2Text,
  Headline2Text,
  LinkText,
} from '@components/Text';
import Image from 'next/image';
import Link from 'next/link';

export default function Hero() {
  return (
    <div className="relative bg-warmWhite pb-8 md:pb-12 lg:pb-6">
      <main>
        <Content className="relative flex flex-row">
          <div className="relative -top-8 flex-1 basis-1/2 md:top-0">
            <div>
              <span className="relative left-1 flex justify-center lg:block">
                <Image
                  src="https://cdn.offertrackr.com/hero_stress_vectors.png"
                  height={213}
                  width={634}
                  alt="Colorful Lines"
                  aria-hidden
                  priority
                />
              </span>
              <div className="relative mx-auto max-w-xl md:-top-8 lg:-top-8 lg:mx-0 lg:max-w-lg lg:text-left xl:-top-12">
                <Headline2Text element="h1">
                  Sell things for more money with less hassle.
                </Headline2Text>
                <Body1Text className="mt-9 max-w-xl text-xl">
                  Create lightweight auctions in seconds. Perfect for selling
                  items at{' '}
                  <LinkText href="/estate-sales">estate sales</LinkText>,{' '}
                  <LinkText href="/garage-sales">garage sales</LinkText>,{' '}
                  <LinkText href="/charity-auctions">charity auctions</LinkText>
                  , on <LinkText href="/social-media">social media</LinkText> or
                  on sites like{' '}
                  <LinkText href="/local-marketplaces">Craigslist</LinkText>.
                </Body1Text>
                <div className="flex w-full justify-center md:w-fit">
                  <Link href="/signin" className="w-full md:w-fit">
                    <Button
                      id="start_trial_hero"
                      className="mt-9 w-full md:w-fit"
                      theme="blue"
                    >
                      Get Started for Free{' '}
                    </Button>
                    <Body2Text className="pt-1 text-center">
                      No credit card required.
                    </Body2Text>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="relative -top-5 left-1 hidden flex-1 basis-1/2 lg:block">
            <Image
              src={`https://cdn.offertrackr.com/hero_girl.png`}
              alt="Girl looking at phone"
              height={694}
              width={600}
              priority
            />
          </div>
        </Content>
      </main>
    </div>
  );
}

import Content from '@components/Content';
import { Body1Text, Headline4Text } from '@components/Text';
import Image from 'next/image';

export default function TryIt() {
  return (
    <div id="try-it" className="bg-warmWhite pt-2 md:pt-28">
      <div className="pb-16 pt-20 md:bg-greyLt md:pb-24 md:pt-28 lg:pb-36 lg:pt-32">
        <Content>
          <div className="md:grid md:grid-cols-3 lg:mx-36 lg:grid-cols-3 lg:gap-4">
            <div className="relative">
              <div className="absolute -top-36 hidden md:block lg:-top-44">
                <Image
                  width={296}
                  height={472}
                  src="https://cdn.offertrackr.com/example_phone.png"
                  alt="App screenshot"
                />
              </div>
              <div className="border-b border-b-greyMed text-center md:hidden">
                <Image
                  width={325}
                  height={340}
                  src="https://cdn.offertrackr.com/example_phone_sm.png"
                  alt="App screenshot"
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-6 md:mt-2 md:ml-12 lg:self-center">
                <Headline4Text element="h2" className="mb-3">
                  Test Drive Our Example
                </Headline4Text>
                <Body1Text className="hidden max-w-sm md:block lg:max-w-[500px]">
                  Text <span className="font-semibold">100</span> to{' '}
                  <a
                    href="sms:+15034967878?&body=100"
                    className="font-semibold"
                  >
                    503-496-7878
                  </a>{' '}
                  to see what your buyers will experience. This is a test item
                  only, offers are not recorded.
                </Body1Text>
              </div>
            </div>
            <div className="col-span-3 md:hidden">
              <div className="mt-2 lg:ml-12 lg:self-center">
                <Body1Text className="max-w-sm lg:max-w-[500px]">
                  Text <span className="font-semibold">100</span> to{' '}
                  <a
                    href="sms:+15034967878?&body=100"
                    className="font-semibold"
                  >
                    503-496-7878
                  </a>{' '}
                  to see what your buyers will experience. This is a test item
                  only, offers are not recorded.
                </Body1Text>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}

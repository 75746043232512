import { classNames } from '@lib';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from './Button';
import Content from './Content';
import { Body1Text, Headline5Text, Subtitle1Text } from './Text';
interface Props {
  enablePurchase?: boolean;
  onPurchase?: (priceId: string) => {};
}

const tiers = [
  {
    id: 'single',
    title: 'One at a time',
    image: 'single_dot.png',
    name: 'Single Trackr',
    price: 5,
    priceId: 'STRIPE_ONE_PACK_PRICE',
    description: 'Find a new home for one of your items',
    textColor: 'text-greyDk',
    borderColor: 'border-greyDk',
    backgroundColor: 'bg-greyDk',
    theme: 'darkgrey',
  },
  {
    id: 'five',
    title: 'Save 20%',
    image: 'five_dots.png',
    name: 'Five Pack',
    price: 20,
    priceId: 'STRIPE_FIVE_PACK_PRICE',
    description: 'Tackle that closet you never go into',
    textColor: 'text-blueMed',
    borderColor: 'border-blueMed',
    backgroundColor: 'bg-blueMed',
    theme: 'blue',
  },
  {
    id: 'ten',
    title: 'Save 40%',
    image: 'ten_dots.png',
    name: 'Ten Pack',
    price: 30,
    priceId: 'STRIPE_TEN_PACK_PRICE',
    description: 'Clean out your entire basement or garage!',
    textColor: 'text-tealDk',
    borderColor: 'border-tealDk',
    backgroundColor: 'bg-tealDk',
    theme: 'green',
  },
];

export default function Tiers({ enablePurchase, onPurchase }: Props) {
  return (
    <Content className="w-full">
      <div className="pt-8 md:grid md:grid-cols-3 md:gap-6 md:space-y-0 md:pb-16 md:pt-16 lg:mx-auto lg:max-w-4xl lg:gap-9 xl:mx-0 xl:max-w-none xl:grid-cols-3">
        {tiers.map(tier => (
          <div key={tier.name} className="mx-auto mb-6 md:mx-0 md:mb-0">
            <div
              className={classNames(
                'justify-left flex items-center rounded-t-xl py-1 pl-6',
                tier.backgroundColor,
              )}
            >
              <Subtitle1Text className="font-normal">
                {tier.title}
              </Subtitle1Text>
            </div>
            <div
              className={classNames(
                'rounded-b-xl border-l border-r border-b bg-white p-6',
                tier.borderColor,
              )}
            >
              <Image
                src={`https://cdn.offertrackr.com/${tier.image}`}
                alt="dots"
                aria-hidden
                width={26}
                height={24}
                className="h-[24px] w-[26px]"
              />
              <Headline5Text element="h3" className="mb-2">
                {tier.name}
              </Headline5Text>
              <Body1Text
                className={classNames('mb-6 max-w-[175px]', tier.textColor)}
              >
                {tier.description}
              </Body1Text>
              <Body1Text className="text-5xl">${tier.price}</Body1Text>
              <div className="mt-6">
                {enablePurchase ? (
                  <Button
                    id={`purchase_tier_${tier.id}`}
                    onClick={() => onPurchase && onPurchase(tier.priceId)}
                  >
                    Purchase
                  </Button>
                ) : (
                  <Link href="/signin">
                    <Button id={`start_trial_tier_${tier.id}`}>
                      Start for Free
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Content>
  );
}

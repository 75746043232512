import Content from '@components/Content';
import { Body1Text, Headline4Text, Headline5Text } from '@components/Text';
import Image from 'next/image';

const features: Feature[] = [
  {
    name: 'Answer Questions',
    description:
      'Our Trackr will tell buyers whether or not your item is still available, instantly.',
    image: 'feature_answer_questions',
    smSize: [78, 72],
    lgSize: [180, 124], //h, w
    lineColor: 'border-blueLt',
  },
  {
    name: 'Negotiate Prices',
    description:
      'We’ll politely weed out bad offers and negotiate with potential buyers to get the best price for you.',
    image: 'feature_negotiate_prices',
    smSize: [91, 72],
    lgSize: [180, 140],
    lineColor: 'border-tealLt',
  },
  {
    name: 'Schedule Pickups',
    description:
      'Our Trackr works with buyers to schedule a time that works for everyone.',
    image: 'feature_schedule_pickups',
    smSize: [78, 72],
    lgSize: [180, 124], //h, w
    lineColor: 'border-orangeLt',
  },
  {
    name: 'Protect Privacy',
    description:
      'Your phone number and email are never shown. Only confirmed buyers get the pickup address.',
    image: 'feature_protect_privacy',
    smSize: [78, 72],
    lgSize: [180, 124], //h, w
  },
];

export default function Features() {
  return (
    <div className="mt-16 mb-16 bg-white">
      <Content>
        <div className="lg:text-center">
          <Headline4Text element="h2">
            Add a <span className="text-blueMed">Trackr</span> to an item
            you&apos;re selling to...
          </Headline4Text>
        </div>

        <div className="mt-8">
          <dl className="md:grid md:grid-cols-2 md:gap-y-10 md:gap-x-8 md:space-y-0 lg:grid-cols-4 lg:gap-y-10 lg:space-y-0">
            {features.map(feature => (
              <div key={feature.name}>
                <div className="relative flex lg:mx-0 lg:block lg:max-w-none">
                  <Image
                    alt={feature.name}
                    src={`https://cdn.offertrackr.com/${feature.image}.png`}
                    aria-hidden
                    height={feature.lgSize[0]}
                    width={feature.lgSize[1]}
                    className="hidden lg:block"
                  />
                  <Image
                    alt={feature.name}
                    src={`https://cdn.offertrackr.com/${feature.image}_sm.png`}
                    aria-hidden
                    height={feature.smSize[0]}
                    width={feature.smSize[1]}
                    className="mt-5 mr-6 self-start lg:hidden"
                  />

                  <div>
                    <Headline5Text element="h3" className="mt-5">
                      {feature.name}
                    </Headline5Text>
                    <Body1Text className="mt-2 lg:max-w-[275px]">
                      {feature.description}
                    </Body1Text>
                  </div>
                </div>
                {feature.lineColor && (
                  <div aria-hidden="true" className="md:hidden ">
                    <div className="pt-6 pb-2">
                      <div className={`${feature.lineColor} border-t`} />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </dl>
        </div>
      </Content>
    </div>
  );
}

import { useState } from 'react';
import ErrorMessage from './ErrorMessage';
import HelpMessage from './HelpMessage';
import { Body2Text } from './Text';
interface Props {
  id: string;
  label?: string;
  value: string;
  helpText?: string;
  errorText?: string;
  minLength?: number;
  maxLength?: number;
  lines?: number;
  className?: string;
  onChange?: (next: string, id: string) => void;
  onLeave?: (next: string, id: string) => void;
  [x: string]: any;
}

export default function TextInput({
  id,
  label,
  value,
  helpText,
  errorText,
  minLength,
  maxLength,
  lines = 1,
  className,
  onChange = () => {},
  onLeave = () => {},
  ...props
}: Props) {
  const [showError, setShowError] = useState(false);

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(event.target.value, id);
  };

  const handleOnBlur = () => {
    onLeave(id, (value || '').trim());
    if (errorText && minLength && (!value || value.length < minLength)) {
      setShowError(true);
    }
  };

  const handleOnFocus = () => {
    setShowError(false);
  };

  return (
    <div className={className}>
      {label && <Body2Text htmlFor={id}>{label}</Body2Text>}
      <div className="mt-1">
        {lines == 1 ? (
          <input
            type="text"
            id={id}
            className="focus:border-blueLt font-Inter focus:ring-blueLt placeholder-greyMed block h-14 w-full rounded-md border-black text-xl font-light text-black"
            aria-describedby={`${id}-description`}
            maxLength={maxLength || 140}
            value={value}
            onChange={handleTextChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            {...props}
          />
        ) : (
          <textarea
            id={id}
            className="focus:border-blueLt font-Inter focus:ring-blueLt placeholder-greyMed block w-full rounded-md border-black p-6 text-xl font-light leading-relaxed text-black"
            aria-describedby={`${id}-description`}
            maxLength={maxLength || 140}
            value={value}
            onChange={handleTextChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            rows={lines}
            {...props}
          />
        )}
      </div>
      <div className="mt-1">
        <HelpMessage show={!showError}>{helpText}</HelpMessage>
        <ErrorMessage show={showError}>{errorText}</ErrorMessage>
      </div>
    </div>
  );
}

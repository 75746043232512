import Layout from '@components/Layout';
import faqs from '@data/faqs-seller';
import Banner from '@marketing/Banner';
import CallToAction from '@marketing/CallToAction';
import Features from '@marketing/Features';
import Footer from '@marketing/Footer';
import FAQ from '@marketing/FrequentlyAskedQuestions';
import Header from '@marketing/Header';
import Hero from '@marketing/Hero';
import HowItWorks from '@marketing/HowItWorks';
import Pricing from '@marketing/Pricing';
import Testimonial from '@marketing/Testimonial';
import TryIt from '@marketing/TryIt';
import Link from 'next/link';

export default function Web() {
  return (
    <Layout
      title="OfferTrackr - Your Local Marketplace Assistant"
      description="Make selling things easier. Use OfferTrackr to automatically track the availability of your items, negotiate prices for you, schedule pickup times, and send out reminders."
      className="bg-white"
    >
      <Banner>
        <Link href="/signin">Limited Time Offer! Track Two Items For Free</Link>
      </Banner>
      <Header />
      <Hero />
      <Features />
      <HowItWorks />
      <TryIt />
      <Pricing />
      <Testimonial />
      <FAQ faqs={faqs} />
      <CallToAction />
      <Footer />
    </Layout>
  );
}

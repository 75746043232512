import Content from '@components/Content';
import { Body1Text, Headline4Text } from '@components/Text';
import Tiers from '@components/Tiers';

export default function Pricing() {
  return (
    <div
      id="pricing"
      className="mb-6 bg-white pt-8 md:bg-white lg:mb-44 lg:pb-24"
    >
      <Content>
        <div className="bg-background-medium">
          <div className="mx-auto max-w-3xl px-4 pt-12 md:px-6 md:text-center lg:px-8">
            <div className="md:align-center md:flex md:flex-col">
              <Headline4Text element="h2" className="mb-6">
                Pricing
              </Headline4Text>
              <div className="flex flex-row justify-center">
                <Body1Text className="max-w-xl text-xl">
                  Your first two Trackrs are free, then priced per Trackr.
                  <span className="ml-2 inline lg:ml-0 lg:block">
                    No monthly subscription fees.
                  </span>
                </Body1Text>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <div className="lg:relative lg:-top-24 lg:mt-24 lg:pb-32">
        <div className="lg:absolute lg:w-full">
          <Tiers />
        </div>
      </div>
    </div>
  );
}

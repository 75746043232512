const faqs = [
  {
    question: 'What is a Trackr?',
    answer:
      'OfferTrackr uses a unique id and phone number, called a Trackr, that potential buyers will use to query about your item. This is used to automatically track the availability and manage offers on your items. You will need one Trackr for each of the items you would like to sell.',
  },
  {
    question: "How do people find the items that I've created Trackrs for?",
    answer:
      'OfferTrackr is not a listing service, we make it easy to track offers on items that you have listed elsewhere. You can list your items on Craigslist, on social media, at yard sales, estate sales, fund raisers or anywhere else you normally post your items for sale.',
  },
  {
    question: 'Is there a trial period available?',
    answer:
      'Yes! Every new account can track offers on two items for free. Once your free offer Trackrs have been used, you can purchase more to track additional items.',
  },
  {
    question: 'Does it cost more to track offers on more expensive items?',
    answer:
      'No, the cost of Trackrs is the same regardless of how expensive your item is.',
  },
  {
    question: "What happens if I don't get any offers?",
    answer:
      'Sometimes it happens, but not to worry. You can always edit the Trackr and try a lower asking price.',
  },
  {
    question: 'Do I have to create all of my Trackrs today?',
    answer:
      'No, there is no time limit to when you need to create your Trackrs after a purchase. Buy in bulk to get extra savings and use them whenever you have items to sell.',
  },
  {
    question: 'How do I get a refund for unused Trackrs?',
    answer:
      "We are currently not offering any refunds for unused Trackrs. Since they don't have an expiration date, you can hold onto them until you have more items to sell.",
  },
];

export default faqs;

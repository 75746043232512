import Content from '@components/Content';
import { Subtitle1Text } from '@components/Text';

interface Props {
  children?: any;
}

export default function Banner({ children }: Props) {
  return (
    <div className="bg-blueLt">
      <Content className="py-4 text-center">
        <Subtitle1Text className="text-white">{children}</Subtitle1Text>
      </Content>
    </div>
  );
}
